import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";


const state = {
  user_token: null,
  user_role: null,
  // url: 'http://localhost:5000',
  // url: process.env.VUE_APP_FILE_IP,
  fcm_token: null,
  url: 'https://minio.osbondgym.com/prd',
  club_id: null,
  club_id_admin: null,
  nama_admin: null,
  user: null,
  sidebarShow: "responsive",
  sidebarMinimize: false,
  sidebarWidth: "0",
  login: null,
  last_query: {},
  asideShow: false,
  darkMode: false,
  alert: { variant: "", msg: "" },
  active_tab: 0,
  tipe_daily: null,
  user_daily: null,
};

const mutations = {
  clear_data(state) {
    state.user_token = null;
  },
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : "responsive";
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : "responsive";
  },
  set(state, [variable, value]) {
    state[variable] = value;
  },
  toggle(state, variable) {
    state[variable] = !state[variable];
  },
  setfcmtoken(state, token){
    state.fcm_token = token
  },
  sidebar_width(value) {
    // console.log("sampe");
    console.log(value);
  },
  set_last_query(state, route) {
    // console.log(route, "route coming");
    state.last_query[`${route.path}`] = route.query;
    // console.log(state.last_query, "hasil");
  },
  set_url(state, val) {
    state.url = val;
    // console.log(val, 'ini val alert');
  },
  set_user_daily(state, val) {
    state.user_daily = val;
    // console.log(val, 'ini val alert');
  },
  set_tipe_daily(state, val) {
    state.tipe_daily = val;
    // console.log(val, 'ini val alert');
  },
  set_active_tab(state, val) {
    state.active_tab = val;
    // console.log(val, 'ini val alert');
  },
  set_user_login(state, val) {
    state.user = val;
    // console.log(val, 'ini val alert');
  },
  set_alert(state, val) {
    state.alert = val;
    // console.log(val, 'ini val alert');
  },
  set_token(state, val) {
    state.user_token = val;
    // console.log(val, 'ini val alert');
  },
  set_role(state, val) {
    state.user_role = val;
    // console.log(val, 'ini val alert');
  },
  set_club(state, val) {
    state.club_id = val;
    // console.log(val, 'ini val alert');
  },
  set_club_admin(state, val) {
    state.club_id_admin = val;
    // console.log(val, 'ini val alert');
  },
  set_nama_admin(state, val) {
    state.nama_admin = val;
    // console.log(val, 'ini val alert');
  },
};

const actions = {};

const res = new Vuex.Store({
  state,
  mutations,
  actions,
  plugins: [
    createPersistedState({
      key: "OSBOND",
    }),
  ],
});
export default res;
