import { getMessaging} from "firebase/messaging";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyADPrfOkRzuseOgHiEbTmuatwtie_xSMLU",
    authDomain: "osbond-a84da.firebaseapp.com",
    projectId: "osbond-a84da",
    storageBucket: "osbond-a84da.firebasestorage.app",
    messagingSenderId: "1043012628068",
    appId: "1:1043012628068:web:340fd7b5c48697020ff971",
    measurementId: "G-4PWRM0REQL"
  };
  
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app, { vapidKey: 'BPYUCPFs_Ye8oPE9YyXJFyuEokPNzc4XOhDrOarWwLGBG1xPRkIUVkWWZFsWfVzVeP0hj10_J4ZdBu0YecAcinY' });

export default messaging