import {createRouter, createWebHistory} from 'vue-router';
import stores from "@/store/store";
import { setToken } from "@/store/utils/auth.js";

const routes = [
    {
        path: '/',
        name: 'Login',
        exact: true,
        component: () => import('./pages/Login.vue'),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        exact: true,
        component: () => import('./components/Dashboard.vue'),
        meta: {
            breadcrumb: [{ label: 'Dashboard' }],
            requiresAuth: true,
        },
    },
    {
        path: '/master/club',
        name: 'Club',
        exact: true,
        component: () => import('./views/club/club.vue'),
        meta: {
            breadcrumb: [{ label: 'Club' }],
            requiresAuth: true,
        },
    },
    {
        path: '/club/register',
        name: 'Register',
        component: () => import('./views/club/add_club.vue'),
        meta: {
            breadcrumb: [
                { label: 'Club', to: '/master/club' },
                { label: 'Add', to: '/club/register' },
            ],
        },
    },
    {
        path: '/club/detail',
        name: 'Detail',
        component: () => import('./views/club/detail_club.vue'),
        meta: {
            breadcrumb: [
                { label: 'Club', to: '/master/club' },
                { label: 'Detail', to: '/club/detail' },
            ],
        },
    },
    {
        path: '/master/studio',
        name: 'Studio',
        exact: true,
        component: () => import('./views/master/ms_studio/ms_studio.vue'),
        meta: {
            breadcrumb: [
                { label: 'Studio', to: '/master/studio'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/user',
        name: 'User',
        exact: true,
        component: () => import('./views/master/ms_user/ms_user.vue'),
        meta: {
            breadcrumb: [
                { label: 'User', to: '/master/user'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/user/detail_membership',
        name: 'Detail Membership',
        exact: true,
        component: () => import('./views/master/ms_user/member/detail_member.vue'),
        meta: {
            breadcrumb: [
                { label: 'User', to: '/master/user'},
                { label: 'Detail', to: '/master/user/detail_membership'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/grade_pt',
        name: 'Grade PT',
        exact: true,
        component: () => import('./views/master/ms_grade_pt/ms_grade_pt.vue'),
        meta: {
            breadcrumb: [
                { label: 'Grade PT', to: '/master/grade_pt'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/gx_class',
        name: 'GX Class',
        exact: true,
        component: () => import('./views/master/ms_gx_class/ms_gx_class.vue'),
        meta: {
            breadcrumb: [
                { label: 'GX Class', to: '/master/gx_class'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/membership',
        name: 'Membership',
        exact: true,
        component: () => import('./views/master/ms_membership/ms_membership.vue'),
        meta: {
            breadcrumb: [
                { label: 'Membership', to: '/master/membership'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/sesi_pt',
        name: 'PT Session',
        exact: true,
        component: () => import('./views/master/ms_sesi_pt/ms_sesi_pt.vue'),
        meta: {
            breadcrumb: [
                { label: 'PT Session', to: '/master/sesi_pt'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/sales',
        name: 'Sales',
        exact: true,
        component: () => import('./views/master/ms_sales/ms_sales.vue'),
        meta: {
            breadcrumb: [
                { label: 'Sales', to: '/master/sales'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/banner',
        name: 'Promo Banner',
        exact: true,
        component: () => import('./views/master/ms_banner/ms_banner.vue'),
        meta: {
            breadcrumb: [
                { label: 'Promo Banner', to: '/master/banner'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/broadcast',
        name: 'Broadcast',
        exact: true,
        component: () => import('./views/master/ms_broadcast/ms_broadcast.vue'),
        meta: {
            breadcrumb: [
                { label: 'Broadcast', to: '/master/broadcast'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/product',
        name: 'Master Product',
        exact: true,
        component: () => import('./views/master/ms_produk/ms_produk.vue'),
        meta: {
            breadcrumb: [
                { label: 'Master Product', to: '/master/product'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/bundle',
        name: 'Master Bundle',
        exact: true,
        component: () => import('./views/master/ms_bundle/ms_bundle.vue'),
        meta: {
            breadcrumb: [
                { label: 'Master Bundle', to: '/master/bundle'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/bundle/add_bundle',
        name: 'Add Bundle',
        exact: true,
        component: () => import('./views/master/ms_bundle/add_bundle.vue'),
        meta: {
            breadcrumb: [
                { label: 'Master Bundle', to: '/master/bundle'},
                { label: 'Add', to: '/master/bundle/add_bundle'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/bundle/detail_bundle',
        name: 'Detail Bundle',
        exact: true,
        component: () => import('./views/master/ms_bundle/detail_bundle.vue'),
        meta: {
            breadcrumb: [
                { label: 'Master Bundle', to: '/master/bundle'},
                { label: 'Detail', to: '/master/bundle/detail_bundle'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/bundle',
        name: 'Master Bundle',
        exact: true,
        component: () => import('./views/master/ms_bundle/ms_bundle.vue'),
        meta: {
            breadcrumb: [
                { label: 'Master Bundle', to: '/master/bundle'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/news',
        name: 'News',
        exact: true,
        component: () => import('./views/master/ms_news/ms_news.vue'),
        meta: {
            breadcrumb: [
                { label: 'News', to: '/master/news'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/parameter',
        name: 'Parameter',
        exact: true,
        component: () => import('./views/master/ms_parameter/ms_parameter.vue'),
        meta: {
            breadcrumb: [
                { label: 'Parameter', to: '/master/parameter'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/pt',
        name: 'PT / Instructor',
        exact: true,
        component: () => import('./views/master/ms_pt/ms_pt.vue'),
        meta: {
            breadcrumb: [
                { label: 'PT / Instructor', to: '/master/pt'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/pt/add_pt',
        name: 'PT',
        exact: true,
        component: () => import('./views/master/ms_pt/add_pt.vue'),
        meta: {
            breadcrumb: [
                { label: 'PT / Instructor', to: '/master/pt'},
                { label: 'Add', to: '/master/pt/add_pt'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/pt/detail_pt',
        name: 'Detail PT',
        exact: true,
        component: () => import('./views/master/ms_pt/detail_pt.vue'),
        meta: {
            breadcrumb: [
                { label: 'PT / Instructor', to: '/master/pt'},
                { label: 'Detail', to: '/master/pt/detail_pt'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/pt/sales_conduct',
        name: 'Detail Sales and Conduct',
        exact: true,
        component: () => import('./views/master/ms_pt/sales_conduct_pt.vue'),
        meta: {
            breadcrumb: [
                { label: 'PT / Instructor', to: '/master/pt'},
                { label: 'Detail', to: '/master/pt/detail_pt'},
                { label: 'Sales and Conduct Detail', to: '/master/pt/sales_conduct'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/pt/add_instructor',
        name: 'Instructor',
        exact: true,
        component: () => import('./views/master/ms_pt/add_instructor.vue'),
        meta: {
            breadcrumb: [
                { label: 'PT / Instructor', to: '/master/pt'},
                { label: 'Add', to: '/master/pt/add_instructor'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/pt/detail_instructor',
        name: 'Detail Instructor',
        exact: true,
        component: () => import('./views/master/ms_pt/detail_instructor.vue'),
        meta: {
            breadcrumb: [
                { label: 'PT / Instructor', to: '/master/pt'},
                { label: 'Detail', to: '/master/pt/detail_instructor'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/master/pt/comission_instructor',
        name: 'Comission Instructor',
        exact: true,
        component: () => import('./views/master/ms_pt/comission_instructor.vue'),
        meta: {
            breadcrumb: [
                { label: 'PT / Instructor', to: '/master/pt'},
                { label: 'Detail', to: '/master/pt/detail_instructor'},
                { label: 'Comission', to: '/master/pt/comission_instructor'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/membership',
        name: 'User Membership',
        exact: true,
        component: () => import('./views/membership/pembelian_membership.vue'),
        meta: {
            breadcrumb: [
                { label: 'Membership', to: '/membership'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/membership/detail_non_member',
        name: 'Detail Membership Non Member',
        exact: true,
        component: () => import('./views/membership/non_member/detail_non_member.vue'),
        meta: {
            breadcrumb: [
                { label: 'Membership', to: '/membership'},
                { label: 'Detail', to: '/membership/detail_non_member'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/membership/detail_member',
        name: 'Detail Membership Member',
        exact: true,
        component: () => import('./views/membership/member/detail_member.vue'),
        meta: {
            breadcrumb: [
                { label: 'Membership', to: '/membership'},
                { label: 'Detail', to: '/membership/detail_member'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/membership/cutting_history_member',
        name: 'Cutting Session History',
        exact: true,
        component: () => import('./views/membership/member/history_cutting.vue'),
        meta: {
            breadcrumb: [
                { label: 'Membership', to: '/membership'},
                { label: 'Detail', to: '/membership/detail_member'},
                { label: 'Cutting Session History', to: '/membership/cutting_history_member'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/membership/history_activity',
        name: 'Activity History',
        exact: true,
        component: () => import('./views/membership/member/history_activity.vue'),
        meta: {
            breadcrumb: [
                { label: 'Membership', to: '/membership'},
                { label: 'Detail', to: '/membership/detail_member'},
                { label: 'Activity History', to: '/membership/history_activity'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/membership/history_member',
        name: 'History Member',
        exact: true,
        component: () => import('./views/transaction/history_member/history_member.vue'),
        meta: {
            breadcrumb: [
                { label: 'Membership', to: '/membership'},
                { label: 'Detail', to: '/membership/detail_member'},
                { label: 'History Member', to: '/membership/history_member'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/membership/history_member/detail',
        name: 'History Member Detail',
        exact: true,
        component: () => import('./views/transaction/history_member/detail_history.vue'),
        meta: {
            breadcrumb: [
                { label: 'Membership', to: '/membership'},
                { label: 'Detail', to: '/membership/detail_member'},
                { label: 'History Member', to: '/membership/history_member'},
                { label: 'History Member Detail', to: '/membership/history_member/detail'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/membership/list_body_growth',
        name: 'Body Growth',
        exact: true,
        component: () => import('./views/membership/member/list_body_growth.vue'),
        meta: {
            breadcrumb: [
                { label: 'Membership', to: '/membership'},
                { label: 'Detail', to: '/membership/detail_member'},
                { label: 'Body Growth', to: '/membership/list_body_growth'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/membership/detail_body_growth',
        name: 'Detail Body Growth',
        exact: true,
        component: () => import('./views/membership/member/detail_body_growth.vue'),
        meta: {
            breadcrumb: [
                { label: 'Membership', to: '/membership'},
                { label: 'Detail', to: '/membership/detail_member'},
                { label: 'Body Growth', to: '/membership/list_body_growth'},
                { label: 'Detail Body Growth', to: '/membership/detail_body_growth'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/transaction',
        name: 'Transaction',
        exact: true,
        component: () => import('./views/transaction/transaction.vue'),
        meta: {
            breadcrumb: [
                { label: 'Transaction', to: '/transaction'},
            ],
            requiresAuth: true,
        },
    },
    
    {
        path: '/transaction/detail_ongoing',
        name: 'On Going Transaction Detail',
        exact: true,
        component: () => import('./views/transaction/on_going/detail_ongoing.vue'),
        meta: {
            breadcrumb: [
                { label: 'Transaction', to: '/transaction'},
                { label: 'Detail', to: '/transaction/detail_ongoing'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/transaction/detail_history',
        name: 'History Transaction Detail',
        exact: true,
        component: () => import('./views/transaction/history/detail_history.vue'),
        meta: {
            breadcrumb: [
                { label: 'Transaction', to: '/transaction'},
                { label: 'Detail', to: '/transaction/detail_history'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/gx_schedule',
        name: 'GX Schedule',
        exact: true,
        component: () => import('./views/jadwal_kelas/jadwal_kelas.vue'),
        meta: {
            breadcrumb: [
                { label: 'GX Schedule', to: '/gx_schedule'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/gx_schedule_finalization',
        name: 'GX Schedule Finalization',
        exact: true,
        component: () => import('./views/jadwal_kelas_final/jadwal_kelas_final.vue'),
        meta: {
            breadcrumb: [
                { label: 'GX Schedule Finalization', to: '/gx_schedule_finalization'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/daily_check_in',
        name: 'Daily Check In',
        exact: true,
        component: () => import('./views/daily_check_in/daily_check_in.vue'),
        meta: {
            breadcrumb: [
                { label: 'Daily Check In', to: '/daily_check_in'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/cutting_sesi_pt',
        name: 'Cutting Session PT',
        exact: true,
        component: () => import('./views/cutting_sesi_pt/cutting_sesi_pt.vue'),
        meta: {
            breadcrumb: [
                { label: 'Cutting Session PT', to: '/cutting_sesi_pt'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/gx_class_schedule',
        name: 'GX Class Schedule',
        exact: true,
        component: () => import('./views/daily_gx_class/daily_gx_class.vue'),
        meta: {
            breadcrumb: [
                { label: 'GX Class Schedule', to: '/gx_class_schedule'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/gx_class_schedule/detail',
        name: 'GX Class Schedule Detail',
        exact: true,
        component: () => import('./views/daily_gx_class/detail_daily_gx.vue'),
        meta: {
            breadcrumb: [
                { label: 'GX Class Schedule', to: '/gx_class_schedule'},
                { label: 'Detail', to: '/gx_class_schedule/detail'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/pt_absence',
        name: 'PT Absence Request',
        exact: true,
        component: () => import('./views/pt_absen_request/pt_absen_request.vue'),
        meta: {
            breadcrumb: [
                { label: 'PT Absence Request', to: '/pt_absence'},
            ],
            requiresAuth: true,
        },
    },
    {
        path: '/club_membership',
        name: 'List Membership Club',
        exact: true,
        component: () => import('./views/master/membership_per_club/membership_per_club.vue'),
        meta: {
            breadcrumb: [
                { label: 'List Membership Club', to: '/club_membership'},
            ],
            requiresAuth: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async function (to, from, next) {
    let token = stores.state.user_token ? stores.state.user_token : null;
    // let acc = stores.state.all_akses ? stores.state.all_akses : [];
    // let token = this.$store.state.rs_token;
    // console.log(token, 'ini token');
    stores.commit("set_last_query", to);
    if (to.path == "/logout") {
      stores.commit("clear_data");
      next("/");
    } else if (!to.meta.requiresAuth) {
      next();
    } else {
        // console.log('ajsd');
      if (!token) {
        // console.log("iki1");
        if (to.path != "/") {
          stores.commit("set_alert", {
            variant: "warning",
            msg: "Silahkan login terlebih dahulu.",
          });
          next("/");
        } else {
            next();
        }
      } else {
        setToken(token);
        next();
      }
    }
  });

export default router;